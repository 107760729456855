<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Удаление кредитной истории</template>
        <template #content>
          <TabView>
            <TabPanel header="Удаление">
              <EventSearchForm
                ref="searchForm"
                :actions="createActions"
              />
            </TabPanel>
            <TabPanel header="Список удалённых КИ">
              <DeletedList ref="dataList"></DeletedList>
            </TabPanel>
          </TabView>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import DeletedList from "@/views/functions/DeletedList.vue";
import EventSearchForm from "@/components/Forms/EventSearchForm.vue";
import FunctionsService from "@/service/FunctionsService";
import ResponseService from "@/service/ResponseService";
import DeleteConfirmationDialog from "@/components/Dialog/DeleteConfirmationDialog.vue";

export default {
  name: "DeleteCh",
  components: {EventSearchForm, DeletedList},
  methods: {
    createActions(event) {
      return [
        {
          label: 'Удалить',
          icon: 'pi pi-trash',
          command: () => this.deleteEvent(event)
        },
      ]
    },
    deleteEvent(event) {
      let messageText = `Кредитная история по заявке / займу с UUID "${event.applicationUuid}" будет удалена!`;

      this.$dialog.open(DeleteConfirmationDialog, {
        props: {
          header: 'Подтвердите удаление',
          modal: true,
          contentStyle: "max-width: 860px"
        },
        data: {
          message: messageText,
          accept: (code) => {
            this.$refs.searchForm.setLoading(true);
            FunctionsService.deleteEvent(event.applicationUuid, code).then((res) => {
              this.$toast.add({
                severity: 'success',
                summary: 'Удаление успешно',
                detail: res.feedback,
                life: 3000
              })
              this.$refs.searchForm.clear()
              this.$refs.dataList.refreshTable()
            })
            .catch((err) => {
              ResponseService.handleErrorResponseWithoutForm(err, this.$toast)
            })
            .finally(() => {
              this.$refs.searchForm.setLoading(false);
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.hide {
  display: none;
}
</style>